<template>
  <div class="forgetbox">
    <div class="forgetbox-box">
      <div class="forgetbox-box-box" :style="{ padding: homepadding }">
        <div class="forgetbox-center" :style="{ height: imgheight }">
          <div class="forgetbox-center-box">
            <div class="box-title">找回密码</div>
            <div class="box-content">
              <el-form ref="seniorform" :model="seniorform" :rules="seniorformrules">
                <el-form-item prop="phonenum">
                  <div class="mobile">
                    <div class="mobile-option">
                      <el-select v-model="seniorform.option" placeholder="中国 +86" style="width: 110px">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                    <div class="mobile-input">
                      <el-input v-model="seniorform.phonenum" placeholder="请输入手机号" maxlength="11" clearable>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="vcode">
                  <div>
                    <el-input v-model="seniorform.vcode" placeholder="请输入短信验证码" clearable>
                      <template #append>
                        <div class="vcode-btn">
                          <!-- 点击获取验证码-发短信 -->
                          <el-button type="text" :disabled="this.vcodedisabled"
                            @click="clickVcodeEvent(this.vcodenum, seniorform.phonenum)">
                            {{ this.vcodenum }}</el-button>
                        </div>
                      </template>
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="password">
                  <div>
                    <el-input v-model="seniorform.password" type="password" autocomplete="off"
                      placeholder="设置密码（6-20个字，包含字母和数字）" show-password clearable>
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div style="text-align: center">
                    <el-button type="primary" class="submit-btn" @click="submitForm('seniorform')">确认修改</el-button>
                  </div>
                  <div style="float: right">
                    <span style="font-size: 14px">已有账号？</span>
                    <el-button type="text" @click="routeraddrClick('/')">登录</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "loginforget",
  data() {
    // 数字input框-校验-(仅整数)
    var checkInputEventInt = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      setTimeout(() => {
        // 判断字符串是否存在小数点
        let result = value.toString().indexOf(".");
        if (result != -1) {
          callback(new Error("请正确输入手机号"));
        } else {
          // 判断字符串是否为文字
          if (isNaN(value)) {
            callback(new Error("请正确输入手机号"));
          } else {
            // 判断字符串是否为数字
            if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
              callback(new Error("请正确输入手机号"));
            } else {
              if (value < 0) {
                callback(new Error("手机号格式错误"));
              } else {
                if (value.length != 11) {
                  callback(new Error("手机号格式不符"));
                } else {
                  callback();
                }
              }
            }
          }
        }
      }, 500);
    };
    return {
      // 下拉-select
      options: [
        {
          value: "+86",
          label: "中国 +86",
        },
      ],

      seniorform: {
        option: "+86",
        phonenum: "",
        vcode: "",
        password: "",
      },
      seniorformrules: {
        phonenum: [
          {
            required: true,
            validator: checkInputEventInt,
            trigger: "blur",
          },
        ],
        vcode: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },

      // 获取验证码按钮-显示文本
      vcodenum: "获取验证码",
      // 获取验证码按钮-是否禁用
      vcodedisabled: false,

      // 动态长宽高
      homepadding: "10% 20%",
      imgheight: "550px",
      sreenheight: null,
      sreenwidth: null,
      mywidth: "1160px",
      myheight: "580px",
      rightpadding: "40% 10% 0",
    };
  },
  methods: {
    // 点击-获取验证码
    clickVcodeEvent(code, mobile) {
      var that = this;
      // 通过接口发送短信
      axios
        .post(
          "/shop/User/sendSms",
          {
            mobile: mobile
          },
          {
            headers: {
              Authen: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            if (code == "获取验证码" || code == "重新获取") {
              that.vcodedisabled = true;
              var i = 59;
              var myvar = window.setInterval(function () {
                if (i == 0) {
                  clearInterval(myvar);
                  that.vcodedisabled = false;
                  that.vcodenum = '重新获取';
                } else {
                  that.vcodenum = i + "s";
                  i = i - 1;
                }
              }, 1000);
            }
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

    },
    // 确认修改-按钮事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("当前提交成功-获取的form表单值：" + JSON.stringify(this.$refs[formName].model));
          let getdatas = this.$refs[formName].model;

          axios
            .post(
              "/shop/User/backPassword",
              {
                mobile: getdatas.phonenum,
                code: getdatas.vcode,
                password: getdatas.password,
              },
              {
                headers: {
                  Authen: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 0) {
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    this.$router.push({ path: "/", query: { phonenum: getdatas.phonenum } }).catch((error) => error);
                  },
                });
              }
            })
            .catch(function (error) {
              // 请求失败处理
              console.log(error);
            });

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 点击登录-跳转页面
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      //仅跳转
      this.$router.push(routeraddr).catch((error) => error);
    },
    //登录页的自动检测切换状态
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      // console.log(this.sreenwidth, this.sreenheight)
      if (this.sreenwidth > 1200) {
        this.homepadding = "9% 18%";
        this.imgheight = this.sreenheight * 0.6 + "px";
        this.rightpadding = "40% 10% 0";
      } else {
        if (this.sreenwidth > 992) {
          if (this.sreenheight > 1300) {
            //适应iPadpro-h
            this.homepadding = "35% 10%";
            this.imgheight = this.sreenheight * 0.4 + "px";
            this.rightpadding = "40% 10% 0";
          } else {
            this.homepadding = "8% 10%";
            this.imgheight = this.sreenheight * 0.7 + "px";
            this.rightpadding = "40% 10% 0";
          }
        } else {
          if (this.sreenwidth > 766) {
            if (this.sreenheight < 420) {
              //适应pixel2XL-h
              this.homepadding = "4% 25%";
              this.imgheight = this.sreenheight * 0.85 + "px";
              this.rightpadding = "8% 10% 0";
            } else {
              this.homepadding = "15% 20%";
              this.imgheight = this.sreenheight * 0.7 + "px";
              this.rightpadding = "40% 10% 0";
            }
          } else {
            if (this.sreenheight < 550) {
              //适应surfceduo-h
              this.homepadding = "2% 20%";
              this.imgheight = this.sreenheight * 0.93 + "px";
              this.rightpadding = "40% 10% 0";

              if (this.sreenheight < 420) {
                //适应MotoG4-h
                this.homepadding = "4% 25%";
                this.imgheight = this.sreenheight * 0.85 + "px";
                this.rightpadding = "8% 10% 0";
              }
              if (this.sreenheight < 330) {
                //适应iPhone5/SE-h
                this.homepadding = "2% 20%";
                this.imgheight = this.sreenheight * 0.9 + "px";
                this.rightpadding = "2% 10% 0";
              }
              if (this.sreenheight < 290) {
                //适应iPhone5/SE-h
                this.homepadding = "0 20%";
                this.imgheight = this.sreenheight + "px";
                this.rightpadding = "0 10%";
              }
            } else {
              this.homepadding = "20% 13%";
              this.imgheight = this.sreenheight * 0.65 + "px";
              this.rightpadding = "30% 10% 0";
            }
          }
        }
      }
    },
  },
  created() {
    window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
    this.getwindowinfo();
  },

  components: {},
});
</script>

<style scoped>
.forgetbox {
  width: 100%;
  height: 100%;
  background: url("../../assets/imgs/login/loginbag.png") center center no-repeat;
  background-size: 100% 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.forgetbox-box {
  display: flex;
  justify-content: center;
}

.forgetbox-center {
  background-color: #ffffff;
  border-radius: 10px;
  min-height: 360px;
}

.forgetbox-center-box {
  padding: 20px;
}

.box-title {
  font-size: 24px;
}

.box-content {
  margin-top: 40px;
}

/* 手机号 */
.mobile {
  display: flex;
  flex-direction: row;
}

.mobile-option {
  margin-right: 20px;
}

.mobile-input {
  width: 100%;
}

/* 验证码 */
.vcode-btn {
  padding: 5px;
  width: 40px;
  text-align: center;
}

.vcode-btn:hover {
  color: var(--el-color-primary);
}

/* 确认修改 */
.submit-btn {
  width: 100%;
}
</style>